import type { DynamicPageQuery } from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-query';
import type { QueryClient } from '@/core/features/react-query/react-query-service';

import React from 'react';

import { updateDynamicPage } from '@/core/features/a-dynamic-page/services/dynamic-page-service';
import { isExternalUrl } from '@/core/utils/url';

/**
 * if the api returns a new url in the dynamic page response, we should update the url for the user
 *
 * - when we request a dynamic page we do not know what page the api returns
 * - there are cases where we request an url that will lead to a redirect to another url
 * - example: activity filter (INDI-315) where we would request /surfen?activity=3 (3 being kitesurfen)
 * - in this case the api will return components for activity 3 with a new url
 * - we then use that url to rewrite the url for the user
 */
export default function useDynamicPageUrlRewrite(
    clientUrl: string,
    query: DynamicPageQuery,
    queryClient: QueryClient,
    setClientUrl: (url: string) => void,
) {
    const response = query.data?.response;
    React.useEffect(() => {
        if (!response?.url) {
            return;
        }

        // page is not rendered on indi page, e.g. for ppset teaser
        if (isExternalUrl(location.href)) {
            return;
        }

        // url is already up to date
        if (clientUrl === response.url) {
            return;
        }

        updateDynamicPage({
            clientUrl,
            newUrl: response.url,
            queryClient,
            setClientUrl,
        });
    }, [clientUrl, queryClient, response, setClientUrl]);
}
