/* eslint-disable perfectionist/sort-objects */
/* eslint-disable fp/no-mutation */
/* eslint-disable no-console */
import { IS_NODE_ENV_DEV, IS_SERVER } from '@/constants/env';
import { getLogPrefix } from '@/core/features/logger/logger';

type ConsoleType = 'error' | 'info' | 'log' | 'warn';

const ignoredMessages: Record<ConsoleType, string[]> = {
    error: [
        'fetchPriority', // not supported in react correctly, remove later
    ],
    info: [],
    log: [],
    warn: [
        'was preloaded using link preload but not used within a few seconds', // icon images are always preloaded
        'elastic-apm-node', // server only module, warning only happens in dev
        'Swiper Loop Warning',
    ],
};

const defaultConsole: Record<ConsoleType, typeof console.log> = {
    error: console.error,
    info: console.info,
    log: console.log,
    warn: console.warn,
};

const consoleOverride =
    (type: ConsoleType) =>
    (...args: unknown[]) => {
        if (includesIgnoredMessage(args, ...ignoredMessages[type])) {
            return;
        }
        if (IS_SERVER) {
            defaultConsole[type](...args);
            return;
        }
        if (IS_NODE_ENV_DEV && type === 'log') {
            defaultConsole[type](...getLogPrefix(), ...args);
            return;
        }
        defaultConsole[type](...args);
    };

console.log = consoleOverride('log');
console.info = consoleOverride('info');
console.warn = consoleOverride('warn');
console.error = consoleOverride('error');

/**
 *
 */
function includesIgnoredMessage(args: unknown[], ...ignoredMessages: string[]) {
    if (!Array.isArray(args)) {
        return false;
    }
    const message = args.join(' ');
    const isIgnored = ignoredMessages.some((ignoredMessage) => message.includes(ignoredMessage));

    return isIgnored;
}
